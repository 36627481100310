import { useState } from "react"
import { useLoading } from "../../hooks/useLoading";

import { Spinner } from "react-bootstrap"
import { toast } from "react-toastify";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import api from "../../services/api";


const token = JSON.parse(localStorage.getItem('token'));

const PLASTIC_BOXES_CODE = ['215566', '213659', '213662', '164059', '220312']

export function UpdateUserBalanceModal({ supplierRegistered }) {
  const [quantity, setQuantity] = useState('')
  const [productCode, setProducCode] = useState('')
  const [reason, setReason] = useState('')

  const loading = useLoading()
  
  const supplier = `${supplierRegistered?.seqpessoa} - ${supplierRegistered?.empresa}`
  const user = token
  

  async function handleUpdateUserBalance() {
    try {
      if (!supplierRegistered.seqpessoa || !productCode || !quantity || !reason.trim()) {
        toast.warning("Preencha todos os campos!")
        return
      }

      if (!PLASTIC_BOXES_CODE.includes(productCode)) {
        toast.warning("Código de produto inválido.")
        return
      }

      if (Number(quantity) <= 0) {
        toast.warning("A quantidade deve ser maior que zero.")
        return
      } 

      if (reason.trim().length < 20) {
        toast.warning("A justificativa deve ter pelo menos 20 caracteres.")
        return
      }

      loading.setLoading(true)

      const data = {
        supplierCode: supplierRegistered.seqpessoa,
        chapa: user.userName,
        productCode,
        quantity,
        reason: reason.trim()
      }
      
      const response = await api.post(`/v1/update_product_balance_api/`, data)
      
      toast.success(response.data.success);
      
      setProducCode('')
      setQuantity('')
      setReason('')

      loading.setLoading(false)
  
      const modal = document.getElementById('updateUserBalance')
      const modalInstance = bootstrapBundleMin.Modal.getInstance(modal);
      modalInstance.hide();
    } catch (error) {
      console.log(error);
      toast.error('Falha ao atualizar produtos.')
      loading.setLoading(false)
    }
  }

  return (
    <>
      <div
      className="modal fade"
      id="updateUserBalance"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '700px', margin: 'auto'}}>
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Atualização de Saldo</h1>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close">
              X
            </button>
          </div>
          <div className="modal-body">
            <form className="">
              <div className="col-sm">
                <div className="form-group">
                  <label className="form-label">Fornecedor:</label>
                  <input
                    id="supplierCode"
                    className="form-control"
                    placeholder="Código do fornecedor"
                    disabled
                    value={supplier}
                  />
                </div>
              </div>
              
              <div className="col-sm">
                <div className="form-group">
                  <label className="form-label">Produto:</label>
                  <input
                    id="productCode"
                    className="form-control"
                    placeholder="Código do produto"
                    onChange={(e) => setProducCode(e.target.value.replace(/\D/g, ''))}
                    value={productCode}
                  />
                </div>
              </div>

              <div className="col-sm">
                <div className="form-group">
                  <label className="form-label">Quantidade:</label>
                  <input
                    id="quantity"
                    className="form-control"
                    placeholder="Quantidade"
                    onChange={(e) => setQuantity(e.target.value.replace(/\D/g, ''))}
                    value={quantity}
                  />
                </div>
              </div>

              <div className="col-sm">
                <div className="form-group">
                  <label className="form-label">Justificativa:</label>
                  <textarea
                    id="reason"
                    className="form-control"
                    placeholder="Informe uma justificativa"
                    rows="6"
                    minLength={20}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </form>
              
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleUpdateUserBalance}
              disabled={loading.isLoading}
              style={{minWidth: '70px'}}
            >
              {loading.isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                'Salvar'
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
