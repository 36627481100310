import { useState } from "react"
import { useLoading } from "../../hooks/useLoading"
import { toast } from "react-toastify"
import { Spinner } from "react-bootstrap"
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min"
import api from "../../services/api"

export function UpdateUserAccountModal({ supplierRegistered }) {
  const [updateEmail, setUpdateEmail] = useState('')
  const loading = useLoading()

  async function handleUpdateUserAccount() {
    try {
      if (!updateEmail) return toast.warning('Informe o E-mail')
      
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(updateEmail)) return toast.warning('Informe um E-mail válido.')

        const data = {
          email_atual: supplierRegistered.email,
          novo_email: updateEmail
        }

        const response = await api.put(`/v1/update_cadastro_api/`, data)
        console.log(response);
        
      
        toast.success('Cadastro atualizado com sucesso')

        setUpdateEmail('')

        const modal = document.getElementById('updateUserAccount')
        const modalInstance = bootstrapBundleMin.Modal.getInstance(modal);
        modalInstance.hide();
    } catch (error) {
      console.log(error.message);
      
      toast.error('Erro ao atualizar cadastro')
    }
  }

  return (
    <div
      className="modal fade"
      id="updateUserAccount"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 
              className="modal-title fs-5" 
              id="staticBackdropLabel"
            >
              Atualizar Cadastro
            </h1>
            <button
              type="button" 
              className="btn btn-close" 
              data-bs-dismiss="modal" 
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body">
            <div className="col">
              <div className="form-group">
                <label className="form-label">E-mail:</label>
                <input
                  className="form-control"
                  placeholder="Informe o novo e-mail para cadastro"
                  onChange={(e) => setUpdateEmail(e.target.value)}
                  value={updateEmail}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleUpdateUserAccount}
              disabled={loading.isLoading}
              style={{minWidth: '70px'}}
            >
              {loading.isLoading ? (
                <Spinner 
                  animation="border" 
                  variant="light" 
                  size="sm" 
                />
              ) : (
                'Salvar'
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
