import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min"

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrapBundleMin.Tooltip(tooltipTriggerEl))


export function UserRegisterInfo({ supplierRegistered }) {
  return (
    <div className="card">
      <h5 className="p-4">Cadastro atual</h5>

      <div className="card-body">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Usuário</th>
              <th scope="col">Email</th>
              <th scope="col">Empresa</th>
              <th scope="col">Status</th>
              <th scope="col-sm"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{supplierRegistered?.usuario}</th>
              <td>{supplierRegistered?.email}</td>
              <td>{supplierRegistered?.empresa}</td>
              <td>{supplierRegistered?.status}</td>
              <td className="d-flex align-items-center justify-content-center">
                <button 
                  type="button" 
                  className="btn btn-sm btn-secondary"
                  data-bs-toggle="modal" 
                  data-bs-title="Editar cadastro"
                  data-bs-target="#updateUserAccount"
                >
                  <i className="fa fa-solid fa-pen"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="d-flex align-items-center float-right mt-4">
          <button
            className="btn btn-secondary mr-4"
            data-bs-toggle="modal"
            data-bs-target="#resetUser"
            disabled={!supplierRegistered}
            // style={{ width: '85px' }}
          >
            Resetar
          </button>

          <button
            className="btn btn-danger mr-4"
            data-bs-toggle="modal"
            data-bs-target="#inactiveUser"
            disabled={!supplierRegistered}
            // style={{ width: '85px' }}
          >
            Inativar
          </button>

          <button
            className="btn btn-info"
            data-bs-toggle="modal"
            data-bs-target="#updateUserBalance"
            disabled={!supplierRegistered}
            // style={{ width: '85px' }}
          >
            Atualizar Saldo
          </button>
        </div>
      </div>
    </div>
  )
}
