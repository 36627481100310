import moment from "moment-timezone";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { CancelOrderProgress } from "./CancelOrderProgress";
import { toast } from "react-toastify";
import { useLoading } from "../../hooks/useLoading";
import { useRef, useState } from "react";
import api from "../../services/api";

export function ModalOrders({
  onCloseModal,
  orders,
  codigoUsuario,
  setOrdersList,
}) {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [progress, setProgress] = useState(0);
  const checkboxOrdersRef = useRef(null);
  const loading = useLoading()

  /* Toggle Checkbox Orders */
  function handleAddCheckedOrders(id) {
    const row = checkboxOrdersRef.current.querySelector(`[id='${id}']`);

    let filteredOrders = [];

    const input = row.querySelector('input');

    if (input.checked) {
      const foundOrder = orders.find(
        (item) => item.numero_pedido_venda === Number(id),
      );

      filteredOrders = [...selectedOrders, foundOrder];
    } else {
      filteredOrders = selectedOrders.filter(
        (lote) => lote.numero_pedido_venda !== id,
      );
    }

    filteredOrders.sort(
      (a, b) => a.numero_pedido_venda - b.numero_pedido_venda,
    );

    setSelectedOrders(filteredOrders);
  }

  function handleAddAllOrders(checked) {
    const rows = checkboxOrdersRef.current.querySelectorAll('tr');
    const formattedOrders = [];

    for (const row of rows) {
      const input = row.querySelector('input');
      input.checked = checked;

      if (checked) {
        const foundOrder = orders.find(
          (item) => item.numero_pedido_venda === Number(row.id),
        );

        formattedOrders.push(foundOrder);
      }
    }

    setSelectedOrders(formattedOrders);
  }

  async function handleCancelOrders() {
    if (!selectedOrders.length) {
      return toast.warning('Selecione pelo menos um pedido.');
    }
    
    loading.setLoading(true)
    
    let ordersProcessed = 0
    let succededOrders = [];
    let failedOrders = [];
    setProgress(0)
    
    for (const order of selectedOrders) {
      const orderToCancel = {
        codigo_usuario: codigoUsuario,
        numero_venda: String(order.numero_pedido_venda),
        numero_empresa: String(order.numero_empresa),
      }
      
      try {
        const response = await api.post('/listaseparacao/v1/cancela_pedido', orderToCancel)
        
        if(response.data.codResult === 1 ) {
          failedOrders.push(orderToCancel.numero_venda)
          ++ordersProcessed
        } else {
          succededOrders.push(orderToCancel.numero_venda)
          ++ordersProcessed
        }
        // Calculate progress
        const currentProgress = (ordersProcessed / selectedOrders.length) * 100;
        
        setProgress(currentProgress);
      } catch (error) {
        failedOrders.push(orderToCancel.numero_venda)
          ++ordersProcessed
        console.log(error);
      }
    }

    if (failedOrders.length) {
      toast.error(`Erro ao cancelar pedido(s):\n${failedOrders.join("\n")}`);
    }
  
    if (succededOrders.length) {
      toast.success(`Pedido(s) cancelado(s) com sucesso:\n${succededOrders.join("\n")}`);
    }

    const filteredOrders = orders.filter(order => !succededOrders.includes(String(order.numero_pedido_venda)))
    if (filteredOrders.length) {
      setOrdersList(filteredOrders)
    }

    loading.setLoading(false)

    setSelectedOrders([]);

    if (!filteredOrders.length) {
      setProgress(0)
      onCloseModal()
    }

    document.querySelector(".allCkbOrders").checked = false
    document
      .querySelectorAll("input[type='checkbox'].ckbOrders")
      .forEach((checkbox) => (checkbox.checked = false));
  }
  
  return (
    <div id="modal-list-prod" className="modal" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl scrollable"
        role="document"
      >
        <div className="modal-content" style={{ width: '85vw' }}>
          <div className="modal-header d-flex align-items-center justify-content-between">
            <h5 className="modal-title">Lista de Pedidos</h5>
            <div className="d-flex align-items-center">
              <CancelOrderProgress progress={progress} selectedOrders={selectedOrders} />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  onCloseModal();
                  setProgress(0)
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div className="modal-body p-0">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="allCkbOrders"
                      checked={selectedOrders.length === orders.length}
                      onChange={(e) =>
                        handleAddAllOrders(e.target.checked)
                      }
                    />
                  </th>
                  <th className="text-center">Nro. Cd.</th>
                  <th className="text-center">Nro. Pedido</th>
                  <th className="text-center">Nome Loja</th>
                  <th className="text-center">Compradores</th>
                  <th className="text-center">Dt. Criação</th>
                  <th className="text-center">Dt. Alteração</th>
                  <th className="text-center">Situação</th>
                  <th className="text-center">Usuário Criação</th>
                  <th className="text-center">Usuário Alteração</th>
                </tr>
              </thead>
              <tbody ref={checkboxOrdersRef}>
                {orders.map((order) => (
                  <tr
                    key={order.numero_pedido_venda}
                    id={order.numero_pedido_venda}
                  >
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox ckbOrders"
                        onChange={() =>
                          handleAddCheckedOrders(
                            order.numero_pedido_venda,
                          )
                        }
                      />
                    </td>
                    <td className="text-center">
                      {order.numero_empresa}
                    </td>
                    <td className="text-center">
                      {order.numero_pedido_venda}
                    </td>

                    <td className="text-center">{order.destinatario}</td>
                    <td className="d-flex justify-content-center align-items-center">
                      {order.compradoresTruncated ||
                        order.compradores}
                      {order.compradoresTruncated && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              {order.compradores}
                            </Tooltip>
                          }
                        >
                          <i className="fa fa-info-circle fa-fw text-gray ml-auto"></i>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td className="text-center">
                      {moment(order.data_inclusao).format(
                        'DD/MM/yyyy [às] hh:mm:ss',
                      )}
                    </td>
                    <td className="text-center">
                      {moment(order.data_alteracao).format(
                        'DD/MM/yyyy [às] hh:mm:ss',
                      )}
                    </td>
                    <td className="text-center">
                      {order.situacao_pedido === 'L'
                        ? 'Liberado'
                        : 'Faturado'}
                    </td>
                    <td className="text-center">
                      {order.usuario_inclusao}
                    </td>
                    <td className="text-center">
                      {order.usuario_alteracao}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary mr-4"
              data-dismiss="modal"
              onClick={handleCancelOrders}
              disabled={loading.isLoading}
              style={{minWidth: '120px'}}
            >
              {loading.isLoading ? (
                <Spinner
                className="ml-2"
                animation="border"
                variant="light"
                size="sm"
              />
              ) : ('Cancelar Pedido')}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                onCloseModal()
                setProgress(0)
              }}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
