export function RegisterSupplierPageInfo() {
  return (
    <div
      className="modal fade"
      id="infoRegisterSupplier"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Informações de Cadastro de Fornecedor</h1>
            <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close">
              X
            </button>
          </div>
          <div className="modal-body">
            <p>Nesta página você terá a funcionalidade de: </p>
            <ul>
              <li>Cadastrar um novo acesso de fornecedor</li>
              <li>Resetar o acesso de fornecedor</li>
              <li>Inativar o acesso de fornecedor</li>
              <li>Alterar saldo de caixas plásticas</li>
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
