import { useState } from "react"
import { useLoading } from "../../hooks/useLoading"
import { toast } from "react-toastify"
import api from "../../services/api"
import { Spinner } from "react-bootstrap"
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min"
import { ProductItem } from "./ProductItem"

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrapBundleMin.Tooltip(tooltipTriggerEl))

const token = JSON.parse(localStorage.getItem('token'));

const PLASTIC_BOXES_CODE = ['215566', '213659', '213662', '164059', '220312']

export function RegisterSupplierModal({ supplier }) {
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [isUpdateProductBalance, setIsUpdateProductBalance] = useState(false)
  const [products, setProducts] = useState([])
  
  const loading = useLoading()

  function validateProducts(products) {
    const invalidProducts = products.filter(
      (product) =>
        !product.productCode || !product.quantity || !product.reason
    );

    const invalidQuantities = products.filter(
      (product) => product.quantity <= 0
    );

    const productsWithInvalidReason = products.filter(
      (product) => product.reason && product.reason.length <= 20
    );

    const invalidCodes = products.filter(
      (product) => !PLASTIC_BOXES_CODE.includes(product.productCode)
    );

    if (invalidProducts.length > 0) {
      toast.warning(
        'Existem produtos com informações incompletas. Verifique e tente novamente.'
      );
      return true;
    }

    if (invalidQuantities.length > 0) {
      toast.warning(
        'A quantidade deve ser maior que zero.'
      );
      return true;
    }

    if (productsWithInvalidReason.length > 0) {
      toast.warning(
        'A justificativa deve conter 20 caracteres ou mais.'
      )
      return true;
    }

    if (invalidCodes.length > 0) {
      toast.warning(
        'Um dos produtos possui códigos inválidos.'
      );
      return true;
    }

    return false;
  }

  async function handleRegisterSupplier() {
    try {
      if (!userName || !email) return toast.warning('Usuário e E-mail são obrigatórios.')

      /* Validando produtos se for adionar saldo de produto */
      if (products.length > 0) {
        const isProductsInvalid = validateProducts(products)

        if (isProductsInvalid) {
          loading.setLoading(false);
          return
        }
      }

      loading.setLoading(true)

      const data = {
        email,
        cpf_cnpj: supplier.cpf_cnpj,
        usuario: userName,
        empresa: supplier.fantasia,
        seqfornecedor: supplier.seqfornecedor,
      }

      const response = await api.post(`/v1/cadastra_fornecedor_api/`, data)

      if (response.request.status === 400) {
        const jsonResponse = JSON.parse(response.request.response)
        const errorMessage = jsonResponse.error
        toast.error(errorMessage)
        loading.setLoading(false)
        return
      }

      const successMessage = response.data.success
      await toast.success(successMessage)

      /* If has products, update product balance */
      if (products.length > 0) {
        await updateProductBalance()
      }

      setUserName('')
      setEmail('')
      setProducts([])
      loading.setLoading(false)

      const modal = document.getElementById('registerUser')
      const modalInstance = bootstrapBundleMin.Modal.getInstance(modal);
      modalInstance.hide();

    } catch (err) {
      loading.setLoading(false)

      if (err.response) {
        console.log(err.response.data.error);
        toast.error(err.response.data.error)
        return
      }

      console.log(err.message);
      toast.error('Falha no envio dos dados.')
    }
  }

  function showUpdateProductBalance(event) {
    if (!event.target.checked) {
      setProducts([]) 
    }
    
    setIsUpdateProductBalance(!isUpdateProductBalance)
  }

  function handleNewProductRow() {
    setProducts([
      ...products,
      {
        productCode: '',
        quantity: '',
        reason: ''
      }
    ])
  }

  function handleRemoveProductRow(row) {
    const filteredProducts = products.filter((_, index) => index !== row)
    setProducts(filteredProducts)
  }

  function handleProductChange (index, updatedProduct) {
    const updatedProducts = [...products];
    
    updatedProducts[index] = updatedProduct;
    setProducts(updatedProducts);
  };
  
  async function updateProductBalance() {
    try {
      if (!products.length) {
        toast.warning('Nenhum produto informado.')
        return
      }

      let successfulProducts = [];
      let failedProducts = [];

      for (const product of products) {
        const data = {
          supplierCode: supplier.seqfornecedor,
          productCode: product.productCode,
          quantity: product.quantity,
          reason: product.reason,
          chapa: token.userName
        }

        try {
          const response = await api.post(`/v1/init_product_balance_api/`, data)
          
          if(response.status === 202) {
            successfulProducts.push({ ...product, message: response.data.success });
          } else {
            failedProducts.push({ ...product, message: response.data.error });
          }
          
        } catch (error) {
          console.log(error)
          failedProducts.push({ ...product, message: error.response.data.error });  
        }
      }
      
      if (successfulProducts.length > 0) {
        toast.success(`Produtos atualizados com sucesso: ${successfulProducts.map(product => product.productCode).join(', ')}`);
      }

      if (failedProducts.length > 0) {
        toast.error(`Erro ao atualizar produtos: ${failedProducts.map(product => product.productCode).join(', ')}`);
      }
    }
  catch (error) {
      console.log(error)
      toast.error('Falha ao atualizar produtos.')
    }
  }

  return (
    <div
      className="modal fade"
      id="registerUser"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Novo Cadastro</h1>
            <button
              type="button" 
              className="btn btn-close" 
              data-bs-dismiss="modal" 
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <div className="col-sm">
                <div className="form-group">
                  <label className="form-label">Usuário:</label>
                  <input
                    className="form-control"
                    placeholder="Usuário para acesso"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                  />
                </div>
              </div>

              <div className="col-sm">
                <div className="form-group">
                  <label className="form-label">E-mail:</label>
                  <input
                    id="txtSeqPessoa"
                    className="form-control"
                    placeholder="E-mail do fornecedor"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>

              <div className="col-sm">
                <div className="form-check ">
                <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id="add-product" 
                  onChange={showUpdateProductBalance} 
                />
                <label htmlFor="add-product" className="form-check-label">Adicionar produto?</label>
                </div>
              </div>

              <div className="dropdown-divider" />

              {isUpdateProductBalance && (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <h5>Inserir saldo de produto</h5>
                    <button 
                      type="button" 
                      className="btn btn-info btn-sm" 
                      onClick={handleNewProductRow}
                    >
                      <i 
                        className="fa fa-plus fa-fw" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top" 
                        data-bs-title="Novo produto"
                      />
                    </button>
                  </div>

                  {products.length > 0 && products.map((product, index) => (
                    <ProductItem 
                      key={index} 
                      index={index} 
                      product={product} 
                      onRemove={handleRemoveProductRow}
                      onProductChange={handleProductChange}
                    />
                  ))}
                </>
              )}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleRegisterSupplier}
              disabled={loading.isLoading}
              style={{minWidth: '70px'}}
            >
              {loading.isLoading ? (
                <Spinner 
                  animation="border" 
                  variant="light" 
                  size="sm" 
                />
              ) : (
                'Salvar'
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
