import { useState } from "react"

export function ProductItem({index, product, onProductChange, onRemove}) {
  const [productData, setProductData] = useState(product)
  
  
  function handleInputChange(event) {
    const { name, value } = event.target

    if (name === 'reason') {
      const updatedData = { ...productData, [name]: value };
      setProductData(updatedData)
      onProductChange(index, { ...product, [name]: value})

      return
    }
    
    const updatedData = { ...productData, [name]: value.replace(/\D/g, '') };
    setProductData(updatedData)
    onProductChange(index, { ...product, [name]: value.replace(/\D/g, '') })
  }

  return (
    <div className="d-flex flex-column mt-4">
      <button 
        type="button" 
        className="btn btn-sm btn-outline-danger ml-auto fw-bold" 
        onClick={() => onRemove(index)}
      >
      <i 
        className="fa-solid fa-x fa-fw" 
        data-bs-toggle="tooltip" 
        data-bs-placement="top" 
        data-bs-title="Novo produto"
      />
      X
      </button>
      <div className="col-sm">
        <div className="form-group">
          <label className="form-label">Produto:</label>
          <input
            className="form-control"
            name="productCode"
            placeholder="Código do Produto"
            value={productData.productCode.replace(/\D/g, '')}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="col-sm">
        <div className="form-group">
          <label className="form-label">Quantidade:</label>
          <input
            className="form-control"
            name="quantity"
            placeholder="Quantidade para atualizar saldo"
            value={productData.quantity.replace(/\D/g, '')}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="col-sm">
        <div className="form-group">
          <label className="form-label">Justificativa:</label>
          <textarea
            id="reason"
            name="reason"
            className="form-control"
            placeholder="Informe uma justificativa"
            rows="4"
            minLength={20}
            value={productData.reason}
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>
    </div>
  )
}
