export function CancelOrderProgress({ progress, selectedOrders }) {
  return (
    <div className="progress d-flex align-items-center">
      <span className="mr-2">Progresso:</span>
      <div
        className="progressbar d-flex align-items-center position-relative"
        role="progressbar"
        aria-valuenow={progress ? progress : "0"}
        aria-valuemin="0"
        aria-valuemax={selectedOrders}
        style={{ width: '120px', height: '15px' }}
      >
        <div className="progress-bar overflow-visible" style={{width: `${progress ? progress : "0"}%`, height: '100%'}}>
          <p className="position-absolute mb-0 mx-auto text-dark font-weight-bold"  style={{ width: '100%' }} >{progress.toFixed(2)}%</p>
        </div>
      </div>
    </div>
  )
}
